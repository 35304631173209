<template>
  <el-scrollbar :native="false">
    <div style="max-height: 100vh;">
      <el-form
        ref="queueForm"
        :rules="rules"
        :model="queue"
        label-position="top"
        size="large"
        :disabled="!canWrite()"
      >
        <el-row type="flex" style="padding-top: 20px">
          <el-col :span="6"></el-col>
          <el-col :span="18">
            <page-header :title="__('Queue')" :contentId="id" />
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item prop="queue_name" :label="__('Name')">
              <el-input
                :value="queue.queue_name"
                @input="$set(queue, 'queue_name', $event)"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              prop="queue_phone_number"
              :label="__('Call center number')"
            >
              <el-input
                :value="queue.queue_phone_number"
                @input="$set(queue, 'queue_phone_number', $event)"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item prop="queue_type" label="Waiter type">
              <span>{{
                __("Note: You can only assign one waiter type per queue")
              }}</span
              ><br />
              <el-radio-group
                v-loading="isLoading"
                :value="queue.ac_plan_id"
                @input="$set(queue, 'ac_plan_id', $event)"
                @change="handleQueueTypeChange()"
              >
                <el-radio
                  :key="this.planIds['Standard']"
                  :label="this.planIds['Standard']"
                  :class="getPlanClass(this.planIds['Standard'])"
                  :disabled="this.planIds['Standard'] === 'Standard'"
                >
                  <template>
                    <img
                      :src="getPlanIcon('Standard')"
                      style="vertical-align: middle"
                      onload="SVGInject(this)"
                    />
                    <span style="margin-left: 5px;">{{ __("Standard") }}</span>
                  </template>
                </el-radio>
                <el-radio
                  :key="this.planIds['Premium']"
                  :label="this.planIds['Premium']"
                  :class="getPlanClass(this.planIds['Premium'])"
                  :disabled="this.planIds['Premium'] === 'Premium'"
                >
                  <template>
                    <img
                      :src="getPlanIcon('Premium')"
                      style="vertical-align: middle"
                      onload="SVGInject(this)"
                    />
                    <span style="margin-left: 5px;">{{ __("Premium") }}</span>
                  </template>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex">
          <el-col :span="8" :offset="6">
            <el-form-item prop="max_waiter" :label="__('Assign Waiters')">
              <el-slider
                v-loading="isLoading"
                v-model="queueWaiters"
                show-input
                :max="maxAllowedWaiters"
              >
              </el-slider>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item prop="is_enabled" :label="__('Queue status')">
              <el-checkbox v-model="queue_status">{{
                __("Enabled")
              }}</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div
        slot="footer"
        style="display: flex;margin-left: 25%;margin-bottom: 20px;margin-top: 20px"
      >
        <save-button
          type="primary"
          @click="submitForm"
          class="submitBtn"
          :disabled="!canWrite()"
          :primaryKey="id"
          variant="CreateUpdate"
        />
        <el-button @click="$emit('cancel')" class="cancelBtn">{{
          __("Cancel")
        }}</el-button>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import { validateQueue } from "@/api/qforme";

export default {
  components: {
    PageHeader,
    SaveButton
  },
  props: {
    queue: {
      type: Object,
      required: true
    },
    id: {
      type: Number,
      required: false,
      default: -1
    }
  },
  data() {
    let validateQueueName = async (rule, value, callback) => {
      let hasError = false;
      await validateQueue(value)
        .then(res => {
          if (res.data.found && value !== this.originalQueueName) {
            hasError = true;
          }
        })
        .catch(() => {
          hasError = false;
        });

      if (hasError) {
        callback(__("Queue name already exists"));
      } else {
        callback();
      }
    };
    return {
      queueCommands: [
        {
          command: "createOrEditForm",
          icon: "campaign_actions/icon_pencil.svg",
          label: __("Edit")
        },
        {
          command: "apiInfo",
          icon: "campaign_actions/icon_info_sign.svg",
          label: __("Api Info")
        },
        {
          command: "delete",
          icon: "campaign_actions/icon_trash_new.svg",
          label: __("Delete")
        }
      ],
      rules: {
        queue_name: [
          { required: true, trigger: "blur", message: __("Name is required") },
          {
            max: 300,
            message: __("Length should be no more than 300 characters"),
            trigger: "blur"
          },
          { validator: validateQueueName, trigger: "blur" }
        ],
        queue_phone_number: [
          {
            required: true,
            trigger: "blur",
            message: __("Phone number is required is required")
          },
          {
            min: 10,
            max: 15,
            message: __("Please provide a valid phone number."),
            trigger: "blur"
          }
        ]
      },
      isSubmitting: false,
      queueWaitersForCalculation: 0,
      originalWaitersAssigned: 0,
      originalPlanId: 0,
      originalQueueName: "",
      maxAllowedWaiters: 0,
      queueWaiters: 0,
      planIds: {
        Standard: "Standard",
        Premium: "Premium"
      }
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapState("qforme", {
      planTypes: state => state.planTypes,
      isLoading: state => state.loading
    }),

    queue_status: {
      get() {
        return this.queue.queue_status === 1;
      },
      set(value) {
        this.$set(this.queue, "queue_status", value === true ? 1 : 0);
      }
    }
  },

  methods: {
    ...mapActions("qforme", {
      createQueue: "createQueue",
      updateQueue: "updateQueue",
      getPlanTypesDetail: "getPlanTypesDetail"
    }),

    getPlanClass(val) {
      // console.log(this.queue.ac_plan_id);
      if (val === this.queue.ac_plan_id) {
        return "rounded-menu-icon-selected";
      } else {
        return "rounded-menu-icon";
      }
    },

    getPlanIcon(plan_type) {
      if (plan_type === "Standard") {
        return require("@/assets/icons//icon-waiter.svg");
      } else if (plan_type === "Premium") {
        return require("@/assets/icons//icon-premium-waiter.svg");
      }
    },

    getPlanId(plan_type) {
      let obj = _.find(this.planTypes, ["plan_type", "QforMe " + plan_type]);
      if (obj) return obj.ac_plan_id;
      else return plan_type;
    },

    async getPlanTypes() {
      await this.getPlanTypesDetail();

      let obj = _.find(this.planTypes, ["plan_type", "QforMe Standard"]);
      if (obj) this.planIds["Standard"] = obj.ac_plan_id;
      else this.planIds["Standard"] = "Standard";

      obj = _.find(this.planTypes, ["plan_type", "QforMe Premium"]);
      if (obj) this.planIds["Premium"] = obj.ac_plan_id;
      else this.planIds["Premium"] = "Premium";

      this.calculateMaxWaiters();
    },

    handleQueueTypeChange() {
      if (this.originalPlanId === this.queue.ac_plan_id) {
        // console.log("queue type change - if");
        this.queueWaitersForCalculation = this.originalWaitersAssigned;
        this.$set(this.queue, "max_waiter", this.originalWaitersAssigned);
      } else {
        // console.log("queue type change - else");
        this.queueWaitersForCalculation = 0;
        this.$set(this.queue, "max_waiter", 0);
      }
      this.calculateMaxWaiters();
    },

    calculateMaxWaiters() {
      let plan = this.planTypes[this.queue.ac_plan_id];
      let max_waiters = this.queueWaitersForCalculation;
      if (plan) {
        if (plan.qfm_pooling_method === "Strict") {
          // console.log("strict");
          max_waiters =
            plan.allocated_unit -
            plan.assigned_waiters +
            this.queueWaitersForCalculation;
        } else {
          // console.log("flexible");
          max_waiters = plan.allocated_unit;
        }
      } else {
        max_waiters = this.queueWaitersForCalculation;
      }
      // console.log("max waiter", max_waiters, this.queueWaitersForCalculation);

      this.queueWaiters = this.queueWaitersForCalculation;
      this.maxAllowedWaiters = max_waiters;
    },

    submitForm() {
      this.$set(this.queue, "max_waiter", this.queueWaiters);
      this.$refs.queueForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process = this.id === -1 ? this.createQueue : this.updateQueue;
          this.$set(this.queue, "ac_id", this.selectedAccountId);
          process(this.queue)
            .then(data => {
              this.isSubmitting = false;
              this.id === -1
                ? this.$message({
                    type: "success",
                    message: __("Queue added Successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Queue updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.$emit("cancel");
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    }
  },
  mounted() {
    // console.log(this.queue.max_waiter);
    this.queueWaitersForCalculation = this.queue.max_waiter;
    this.originalWaitersAssigned = this.queue.max_waiter;
    this.originalPlanId = this.queue.ac_plan_id;
    this.originalQueueName = this.queue.queue_name;
    this.getPlanTypes();
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/node_common.scss";

/*

::v-deep .el-input-number {
  .el-input-number__decrease:hover {
    color: $content-theme-hover-color;
  }
  .el-input-number__increase:hover {
    color: $content-theme-hover-color;
  }
  .el-input-number__decrease {
    background-color: #f5f5f5;
  }
  .el-input-number__increase {
    background-color: #f5f5f5;
  }
}

::v-deep .el-checkbox-group {
  .el-checkbox-button.is-focus .el-checkbox-button__inner {
    border-color: $content-theme-hover-color;
  }
  .el-checkbox-button .el-checkbox-button__inner:hover {
    color: $content-theme-hover-color;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner:hover {
    color: $content-theme-color-white;
  }
}

::v-deep .el-checkbox {
  :hover {
    border-color: $content-theme-color;
  }
  .el-checkbox__input .el-checkbox__inner {
    border-color: $content-theme-color;
  }
  .el-checkbox__input + .el-checkbox__label {
    color: $content-theme-color;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }
}

::v-deep .el-date-picker {
  :hover {
    color: $content-theme-hover-color;
  }
  .el-date-picker__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-date-picker__label {
    color: $content-theme-color;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
*/

.api-key {
  word-break: break-word;
  padding-right: 5px;
}

.editContentItemModal {
  padding: 0;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}

::v-deep .rounded-menu-icon-selected {
  fill: $content-theme-color;
  path {
    fill: $content-theme-color;
  }
}

.queueActionDropDown {
  color: white;
  background: $content-theme-color;
  border-radius: 6px;
  margin-top: 20px;

  ::v-deep button {
    background: $content-theme-color;
    border-color: transparent;
    height: 44px;
    &:disabled {
      color: white;
      border-color: var(--theme-outline-color) !important;
    }
  }

  &:hover {
    ::v-deep button {
      //background: $content-theme-hover-color;
    }
  }
}
</style>
